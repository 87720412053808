<template>
    <div>
        <div class="modal fade" id="forecastModal" tabindex="-1" aria-labelledby="forecastModalLabel" aria-hidden="true" style="overflow:auto">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="forecastModalLabel" class="font-weight-bold">Add Item</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>
                    
                    <div class="modal-body">
                        <CRow>
                            <div class="col-sm-1">
                                <label class="form-label font-weight-bold">PO ke</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-sm-1">
                                <CSelect id="PO" class="font-weight-bold" :options="POData" :value.sync="PO" />
                                <label id="errorPO" class="form-error" style="display: none; color: red;"></label>
                            </div>
                            
                            <div class="col-sm-3"></div>
                            
                            <div class="col-sm-2">
                                <label class="form-label font-weight-bold">Tipe Detail</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-sm-5">
                                <v-select id="TypeDetail" class="pb-3" :options="TypeDetailData" v-model="TypeDetail" @input="onChangeTypeDetail()"/>
                                <label id="errorTypeDetail" class="form-error" style="display: none; color: red;"></label>
                            </div>
                        </CRow>

                        <kendo-grid :key="GridReload" 
                                    ref="grid" 
                                    :data-source="DataSource" 
                                    :filterable="filterableConfig"
                                    :sortable="true"
                                    :noRecords="noRecords"
                                    :pageable="true"
                                    :resizable="true"
                                    >
                            <kendo-grid-column  :field="'product_code'"
                                                :title="'Kode'"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                            <kendo-grid-column  :field="'item_name'"
                                                :title="'Nama'"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                            <kendo-grid-column  :field="'type_detail_id'"
                                                :title="'Kode Tipe Detail'"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                            <kendo-grid-column  :field="'type_detail_name'"
                                                :title="'Nama Tipe Detail'"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                            <kendo-grid-column  :command="{ text: 'Select', click: selectForecast }"
                                                :title="'Action'"
                                                :width="100"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                :attributes="{style: 'text-align:center;'}"></kendo-grid-column>
                        </kendo-grid>

                        <br/>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
        <detail-forecast-form ref="detailForecastForm" :saveClick="saveClick"/>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import projectServices from '../Script/ProjectServices';
import detailForecastForm from './DetailForecastFrom.vue';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'ForecastForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'detail-forecast-form': detailForecastForm,
    },
    props: ['saveForecastGrid',
            'PriceType',
            'totalPO',
            'Storage'],
    data: function () {
        return {
            PO: 0,
            POData : [],
            TypeDetail: '',
            TypeDetailData: [],
            GridReload: 0,
            DataSource: [],
            noRecords: {
                template: `<div style="text-center; width:10000px"><label>No Records Found</label></div>`
            },
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            }
        }
    },
    methods: {
        async addClick(){
            this.TypeDetailData = await projectServices.readTypeDetailQuery();
            this.TypeDetail = '';

            var priceCategoryId = this.PriceType.value;
            var storageId = this.Storage.value;
            var totalPO = this.totalPO;

            if(totalPO < 1){
                this.POData = [{value: 0}];
                this.po = this.POData[0].value;
            }
            else{
                this.POData = [];
                for (let i = 1; i <= totalPO; i++) {
                    var str = {value: i}
                    this.POData.push(str);
                }
                this.PO = this.POData[0].value;
            }

            this.DataSource = new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                priceCategoryId : priceCategoryId,
                                storageId : storageId,
                                typeDetailId : null
                            }
                            
                            return { 
                                query: projectServices.readItemQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetItemForcastV2 == null){
                            return [];
                        }else{
                            return response.data.GetItemForcastV2;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetItemForcastV2 == null){
                            return 0;
                        }else{
                            return response.data.GetItemForcastV2.length;
                        }
                    },
                }
            })
            this.GridReload++;
            window.$('#forecastModal').modal('show');
        },
        saveClick(itemData){
            let data = [];
            var str = { 
                    po: this.PO, 
                    item_id: itemData.item_id,
                    product_code: itemData.product_code, 
                    item_name: itemData.item_name,
                    type_detail_id: itemData.type_detail_id,
                    base_uom: itemData.base_uom,
                    uom_conv: itemData.uom_conv,
                    conv_amount: itemData.conv_amount,
                    quantity: itemData.quantity,
                    //price
                    base_price_project: itemData.base_price_project,
                    price_project: itemData.price_project,
                    total_price_project: 0,
                    //price discount
                    base_price_project_discount: 0,
                    price_project_discount: 0,
                    total_price_project_discount: 0,
                    //price discount cash
                    base_price_project_discount_cash: 0,
                    price_project_discount_cash: 0,
                    total_price_project_discount_cash: 0,
                };
            data.push(str);
            this.$props.saveForecastGrid(data);
        }, 
        selectForecast: function(e) {
            e.preventDefault();
            var grid = this.$refs.grid.kendoWidget();
            var dataItem = grid.dataItem($(e.currentTarget).closest("tr"));
            this.$refs.detailForecastForm.addClick(dataItem);
        },
        onChangeTypeDetail() {
            var priceCategoryId = this.PriceType.value;
            var storageId = this.Storage.value;
            var typeDetail = (this.TypeDetail == null ? null : this.TypeDetail.value);

            this.DataSource = new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                priceCategoryId : priceCategoryId,
                                storageId : storageId,
                                typeDetailId : typeDetail
                            }
                            
                            return { 
                                query: projectServices.readItemQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetItemForcastV2 == null){
                            return [];
                        }else{
                            return response.data.GetItemForcastV2;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetItemForcastV2 == null){
                            return 0;
                        }else{
                            return response.data.GetItemForcastV2.length;
                        }
                    },
                }
            });
            this.GridReload++;
        }
    }
}
</script>
