<template>
    <div>
        <div class="modal fade" id="salesModal" tabindex="-1" aria-labelledby="salesModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="salesModalLabel" class="font-weight-bold">Add Sales</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <kendo-grid ref="grid"
                            :data-source="dataSource"
                            :filterable="filterableConfig"
                            :sortable="true"
                            :pageable="pageableConfig"
                            :columns="columns"
                            :resizable="true"
                            >
                        </kendo-grid>
                        <br>
                        <CButton type="button" @click="saveClick()" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
        <input hidden id="hf_ID_SalesFilter" v-model="hf_ID_SalesFilter" style="float: right; width : 80%; height:37px">
        <input hidden id="hf_CheckAll_SalesFilter" v-model="hf_CheckAll_SalesFilter"  style="float: right; width : 20%; height:37px; text-align: center;"/>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import projectServices from '../Script/ProjectServices';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'SalesForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['saveSalesGrid'],
    mounted:  function () {
        var vueComponent =this;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;

        GridElement.on("change", "#masterCheckBoxSalesFilter", function (e) {
            e.preventDefault();
            var state= this.checked;
            var data = grid._data;
            
            for (var i = 0; i < data.length; i++) {
                var datarow = data[i]
                var elementRow =  grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").find(".cbSalesFilter")[0];
                
                if (elementRow != null) {
                    if (state) {
                    elementRow.checked = true;
                    grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").addClass("k-state-selected");
                    }
                    else
                    {
                    elementRow.checked = false;
                    grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").removeClass("k-state-selected");
                    }
                }
             }
             var hf_CheckAll_SalesFilter = vueComponent.hf_CheckAll_SalesFilter;
              if (state && hf_CheckAll_SalesFilter == "true") {
                    vueComponent.hf_CheckAll_SalesFilter="true";
                }
                else if (!state && hf_CheckAll_SalesFilter == "true") {
                    vueComponent.hf_CheckAll_SalesFilter="false";
                    vueComponent.hf_ID_SalesFilter="";
                }
                else if (state && hf_CheckAll_SalesFilter == "false") {
                    vueComponent.hf_CheckAll_SalesFilter= "true";
                    vueComponent.hf_ID_SalesFilter="";
                }
                else if (!state)
                    vueComponent.hf_CheckAll_SalesFilter="false";
                else
                    vueComponent.hf_CheckAll_SalesFilter= "true";
        });

        GridElement.on("change", ".cbSalesFilter", function (e) {
            e.preventDefault();
            var checked = this.checked;
            var rowElement = grid.tbody.find(e.target.closest("tr"));
            var row = grid.dataItem(e.target.closest("tr"));
            var hfIDValue = vueComponent.hf_ID_SalesFilter;
            var uid = row.uid;
            var dataItem = row.contact_id.toString();
           
            var checkedIdSalesArray = hfIDValue.split(",");

            var lastvalueHfid =checkedIdSalesArray.slice(-1)[0];
            var hf_CheckAll_SalesFilter = vueComponent.hf_CheckAll_SalesFilter;
           
            if (checked)
                grid.tbody.find("tr[data-uid='" +uid+ "']").addClass("k-state-selected");
            else
                grid.tbody.find("tr[data-uid='" +uid+ "']").removeClass("k-state-selected");
            
            var lastIndex = (hfIDValue.length - dataItem.length)
            if (!checked && hf_CheckAll_SalesFilter == "true") {
            if (hfIDValue == "")
               hfIDValue = dataItem;
                else {
                    //Check if not exist
                    if (hfIDValue.indexOf(dataItem) == "-1")
                        hfIDValue = hfIDValue + "," + dataItem;
                }
             }
            else if (!checked && hf_CheckAll_SalesFilter == "false" || hf_CheckAll_SalesFilter == "") {    
                if(lastvalueHfid != dataItem.toString())
                {
                    hfIDValue = hfIDValue.replace(dataItem + ",", '');
                }
                else if (lastvalueHfid == dataItem.toString()) 
                {
                    hfIDValue = hfIDValue.replace("," + dataItem, '');  
                }
                else
                {
                    hfIDValue = hfIDValue.replace(dataItem, '');
                }
                //Check kalau bukan di index terakhir
                if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) != lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace(dataItem + ",", '');
                //Check di index terakhir
                else if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) == lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace("," + dataItem, '');   
                else
                    hfIDValue = hfIDValue.replace(dataItem, '');
            }
            else if (checked && hf_CheckAll_SalesFilter == "true") {
                //Check kalau bukan di index terakhir
                if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) != lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace(dataItem + ",", '');
                //Check di index terakhir
                else if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) == lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace("," + dataItem, '');
                else
                    hfIDValue = hfIDValue.replace(dataItem, '');
            }
            else if (checked && hf_CheckAll_SalesFilter == "false" || hf_CheckAll_SalesFilter == "") {
                if (hfIDValue == "")
                    hfIDValue = dataItem;
                else {
                    if(!checkedIdSalesArray.includes(dataItem))
                    {
                        hfIDValue = hfIDValue + "," + dataItem;
                    }
                }
            }
            if (checked) {
                rowElement.addClass("k-state-selected");
            } else {
                rowElement.removeClass("k-state-selected");
            }
            vueComponent.hf_ID_SalesFilter = hfIDValue
        });
    },
    data: function () {
        return {
            hf_CheckAll_SalesFilter:"false",
            hf_ID_SalesFilter:"",
            dataSource: [],
            columns: [
                { template:this.columnTemplate, headerTemplate: this.headerTemplate, width: 40, headerAttributes:{ style: "text-align:center;" }, attributes:{style: "text-align:center;"} },
                { field: "contact_code", title: "Kode", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "contact_name", title: "Nama", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "metadata.profile.position", title: "Posisi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    methods: {
        headerTemplate() {
            // return `<input type="checkbox" id="masterCheckBoxSalesFilter"" />`
            return '';
        },
        columnTemplate() {
            return `<input type="checkbox" class="cbSalesFilter" />`
        },
        addClick(){ 
            this.dataSource = new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            return { query: projectServices.readContactQuery(),
                                     variables: {"type": "Employee",
                                                 "status": "A"}};
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetContact == null){
                            return [];
                        }else{
                            return response.data.GetContact;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetContact == null){
                            return 0;
                        }else{
                            return response.data.GetContact.length;
                        }
                    },
                }
            });
            this.hf_CheckAll_SalesFilter = "false";
            this.hf_ID_SalesFilter = "";
            window.$('#salesModal').modal('show');
        },
        saveClick(){
            var data = this.$globalfunc.objectToArrayConverter(this.dataSource._data, 'Project-ProjectSalesAdd');
            var newData = projectServices.gridSelector(this.hf_ID_SalesFilter, this.hf_CheckAll_SalesFilter, data);
            var salesData = this.$globalfunc.objectToArrayConverter(newData, 'Project-ProjectSales');
            this.$props.saveSalesGrid(salesData);
            window.$('#salesModal').modal('hide');
        }
    }
}
</script>
