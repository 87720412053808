<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Project : {{ProjectCode}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Kode</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="ProjectCode" v-model="ProjectCode" class="font-weight-bold" />
                            <label id="errorProjectCode" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Nama</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="ProjectName" v-model="ProjectName" class="font-weight-bold" />
                            <label id="errorProjectName" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tgl. Mulai</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="ProjectDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorProjectDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Provinsi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Province" class="pb-3" :options="ProvinceData" v-model="Province" @input="onChange()" />
                            <label id="errorProvince" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                            <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Kota</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="City" class="pb-3" :options="CityData" v-model="City"/>
                            <label id="errorCity" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Area</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Area" class="pb-3" :options="AreaData" v-model="Area"/>
                            <label id="errorArea" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Durasi (bln)</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput type="number" id="Duration" v-model="Duration" class="font-weight-bold" />
                            <label id="errorDuration" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                            <div class="col-sm-label">
                                <label class="form-label font-weight-bold">Diskon Cash (%)</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput type="number" id="DiscCash" v-model="DiscCash" class="font-weight-bold" @change="onChangeDiscCash()"/>
                            <label id="errorDiscCash" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Jenis Harga</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="PriceType" class="pb-3" :options="PriceTypeData" v-model="PriceType" @input="onChangePriceType()" />
                            <label id="errorPriceType" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Limit</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput type="number" id="Limit" v-model="Limit" class="font-weight-bold" @keypress="numbersOnly($event)"/>
                            <label id="errorLimit" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Kacab</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Kacab" class="pb-3" :options="KacabData" v-model="Kacab" @input="onChangeKacab()" />
                            <label id="errorKacab" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Salesman</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Salesman" class="pb-3" :options="SalesmanData" v-model="Salesman" />
                            <label id="errorSalesman" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Forecast</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Forecast" class="font-weight-bold" :options="ForecastData" :value.sync="Forecast" @change="onChangeForecast()"/>
                            <label id="errorForecast" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                                <label class="form-label font-weight-bold">Jumlah PO</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput type="number" id="TotalPO" v-model="TotalPO" class="font-weight-bold" />
                            <label id="errorTotalPO" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                                <label class="form-label font-weight-bold">Sisa Limit</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput type="number" id="LimitRemains" v-model="LimitRemains" class="font-weight-bold" readonly/>
                            <label id="errorLimitRemains" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">KPA (%)</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput type="number" id="KPA" v-model="KPA" class="font-weight-bold" @change="onChangePercentage()"/>
                            <label id="errorKPA" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Attachment</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <div class="row">
                                <div class="col-8 p-0 pl-3">
                                    <div class="form-control">
                                        <div v-if="this.FileStatus == 'Uploaded'">
                                            <a href="#" @click="fileClick()" class="label-file" id="uploadedFile"><label>{{File}}</label></a>
                                        </div>
                                        <div v-if="this.FileStatus == 'New'">
                                            <label class="label-file">{{File}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 p-0 px-2">
                                    <label for="File" class="form-control" style="text-align: center;"><i class="fa fa-upload"></i></label>
                                    <input type="file" id="File" @input="onChangeFile()" style="display:none"/>
                                </div>
                                <div class="col-2 p-0 pr-3">
                                    <CButton type="button" @click="deleteAttachmentClick()" id="deleteAttachmentButton" color="danger" style="width:100%"> <i class="fa fa-trash"></i> </CButton>
                                </div>
                            </div>
                            <label id="errorFile" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                                <label class="form-label font-weight-bold required">Gudang</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Storage" class="pb-3" :options="StorageData" v-model="Storage" @input="onChangeStorage()"/>
                            <label id="errorStorage" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Cara Bayar</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Cash" class="font-weight-bold" :options="CashData" :value.sync="Cash" @change="onChangeCash()"/>
                            <label id="errorCash" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                            <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Pra Project</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="PreProject" class="pb-3" :options="PreProjectData" v-model="PreProject"/>
                            <label id="errorPreProject" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Alamat</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea class="form-control" rows="2" v-model="Address"></textarea>
                            <label id="errorAddress" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Keterangan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea class="form-control" rows="2" v-model="Note"></textarea>
                            <label id="errorNote" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <div>
                                <label class="form-label font-weight-bold">PRM</label>&nbsp;
                                <input type="checkbox" v-model="PRM"/>
                            </div>
                            <label id="errorPRM" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <hr>
                    <CTabs>
                        <CTab title="Type Detail" id="tab-1" active>
                            <div class="py-2" v-if="this.Forecast == 'None'">
                                <button class="btn btn-primary"                     
                                    @click="checkAllTypeDetailClick(true)">
                                    <i class="fa fa-check"></i> Check All
                                </button>
                                &nbsp;
                                <button class="btn btn-danger"                     
                                    @click="checkAllTypeDetailClick(false)">
                                    <i class="fa fa-square"></i> Uncheck All
                                </button>
                            </div>

                            <div :key="gridTypeDetailReload">
                                <datasource ref="typeDetailDataSource" :data="this.TypeDetailGridData" :page-size="10" :schema-model-fields="this.TypeDetailSchemaModel" />

                                <div v-if="this.Forecast == 'None'">
                                    <kendo-grid ref="gridTypeDetail"
                                        :data-source-ref="'typeDetailDataSource'"
                                        :columns="this.TypeDetailGridColumnsNone"
                                        :editable="true"
                                        :cellClose="onChangeTypeDetailGrid"
                                        :filterable="true"
                                        :sortable="true"
                                        :pageable="true"
                                        :resizable="true">
                                    </kendo-grid>
                                </div>
                                <div v-else>
                                    <kendo-grid ref="gridTypeDetail"
                                        :data-source-ref="'typeDetailDataSource'"
                                        :columns="this.TypeDetailGridColumns"
                                        :editable="true"
                                        :cellClose="onChangeTypeDetailGrid"
                                        :filterable="true"
                                        :sortable="true"
                                        :pageable="true"
                                        :resizable="true">
                                    </kendo-grid>
                                </div>
                            </div>
                        </CTab>
                        <CTab title="Project Afiliasi" id="tab-2" active>
                            <div class="py-2">
                                <button class="btn btn-primary"                     
                                    @click="addAfiliationClick()">
                                    <i class="fa fa-plus"></i> Add
                                </button>
                            </div>
                        
                            <datasource ref="afiliationDataSource" :data="this.AfiliationGridData" :page-size="10" :schema-model-fields="this.AfiliationSchemaModel"/>

                            <kendo-grid ref="gridAfiliation"
                                :data-source-ref="'afiliationDataSource'"
                                :columns="this.AfiliationGridColumns"
                                :editable="true"
                                :filterable="true"
                                :sortable="true"
                                :pageable="true"
                                :resizable="true">
                            </kendo-grid>

                            <afiliation-form ref="afiliationForm" :saveAfiliationGrid="saveAfiliationGrid"/>
                        </CTab>
                        <CTab title="Distributor" id="tab-3" active>
                            <div class="py-2">
                                <button class="btn btn-primary"                     
                                    @click="addDistributorClick()">
                                    <i class="fa fa-plus"></i> Add
                                </button>
                            </div>
                            
                            <datasource ref="distributorDataSource" :data="this.DistributorGridData" :page-size="10" :schema-model-fields="this.DistributorSchemaModel"/>

                            <kendo-grid ref="gridDistributor"
                                :data-source-ref="'distributorDataSource'"
                                :columns="this.DistributorGridColumns"
                                :editable="true"
                                :filterable="true"
                                :sortable="true"
                                :pageable="true"
                                :resizable="true">
                            </kendo-grid>

                            <distributor-form ref="distributorForm" :saveDistributorGrid="saveDistributorGrid"/>
                        </CTab>
                        <CTab title="Forecast" id="tab-4" v-if="this.Forecast == 'Forecast 1'">
                            <div class="py-2">
                                <button class="btn btn-primary"                     
                                    @click="addForecastClick('Forecast 1')">
                                    <i class="fa fa-plus"></i> Add
                                </button>
                            </div>

                            <datasource ref="forecastDataSource" :data="this.ForecastGridData" :page-size="10" :schema-model-fields="this.Forecast1SchemaModel"
                                :aggregate="[
                                    { field: 'product_code', aggregate: 'count' },
                                    { field: 'total_price_project', aggregate: 'sum' },
                                    { field: 'total_price_project_discount', aggregate: 'sum' },
                                    { field: 'total_price_project_discount_cash', aggregate: 'sum' },
                                ]"
                            />

                            <kendo-grid ref="gridForecast"
                                :key="gridReload"
                                :data-source-ref="'forecastDataSource'"
                                :columns="this.Forecast1GridColumns"
                                :editable="true"
                                :cellClose="recountForecast"
                                :filterable="true"
                                :sortable="true"
                                :pageable="true"
                                :resizable="true">
                            </kendo-grid>

                            <forecast-form ref="forecastForm" :totalPO="this.TotalPO" :saveForecastGrid="saveForecastGrid" v-bind:price-type="this.PriceType" v-bind:storage="this.Storage" />
                        </CTab>
                        <CTab title="Forecast" id="tab-4" v-if="this.Forecast == 'Forecast 2'">
                            <div class="py-2">
                                <button class="btn btn-primary"                     
                                    @click="addForecastClick('Forecast 2')">
                                    <i class="fa fa-plus"></i> Add
                                </button>
                            </div>

                            <datasource ref="forecastDataSource" :data="this.ForecastGridData" :page-size="10" :schema-model-fields="this.Forecast2SchemaModel"
                                :aggregate="[
                                    { field: 'product_code', aggregate: 'count' },
                                    { field: 'total_price_project', aggregate: 'sum' },
                                    { field: 'total_price_project_discount', aggregate: 'sum' },
                                    { field: 'total_price_project_discount_cash', aggregate: 'sum' },
                                ]"
                            />

                            <kendo-grid ref="gridForecast"
                                :key="gridReload"
                                :data-source-ref="'forecastDataSource'"
                                :columns="this.Forecast2GridColumns"
                                :editable="true"
                                :cellClose="recountForecast"
                                :filterable="true"
                                :sortable="true"
                                :pageable="true"
                                :resizable="true">
                            </kendo-grid>

                            <forecast-form ref="forecastForm" :totalPO="this.TotalPO" :saveForecastGrid="saveForecastGrid" v-bind:price-type="this.PriceType" v-bind:storage="this.Storage" />
                        </CTab>
                        <CTab title="Konsultan" id="tab-5" active>
                            <div class="py-2">
                                <button class="btn btn-primary"                     
                                    @click="addConsultantClick()">
                                    <i class="fa fa-plus"></i> Add
                                </button>
                            </div>
                        
                            <datasource ref="consultantDataSource" :data="this.ConsultantGridData" :page-size="10" :schema-model-fields="this.ConsultanSchemaModel"/>

                            <kendo-grid ref="gridConsultant"
                                :data-source-ref="'consultantDataSource'"
                                :columns="this.ConsultantGridColumns"
                                :editable="true"
                                :filterable="true"
                                :sortable="true"
                                :pageable="true"
                                :resizable="true">
                            </kendo-grid>

                            <consultant-form ref="consultantForm" :saveConsultantGrid="saveConsultantGrid"/>
                        </CTab>
                        <CTab title="Sales" id="tab-6" active>
                            <div class="py-2">
                                <button class="btn btn-primary"                     
                                    @click="addSalesClick()">
                                    <i class="fa fa-plus"></i> Add
                                </button>
                            </div>
                        
                            <datasource ref="salesDataSource" :data="this.SalesGridData" :page-size="10" :schema-model-fields="this.SalesSchemaModel"/>

                            <kendo-grid ref="gridSales"
                                :data-source-ref="'salesDataSource'"
                                :columns="this.SalesGridColumns"
                                :editable="true"
                                :filterable="true"
                                :sortable="true"
                                :pageable="true"
                                :resizable="true">
                            </kendo-grid>

                            <sales-form ref="salesForm" :saveSalesGrid="saveSalesGrid"/> 
                        </CTab>
                        <CTab title="Lain-lain" id="tab-7" active>
                            <div class="py-2">
                                <button class="btn btn-primary"                     
                                    @click="addOtherClick()">
                                    <i class="fa fa-plus"></i> Add
                                </button>
                            </div>

                            <datasource ref="otherDataSource" :data="this.OtherGridData" :page-size="10" :schema-model-fields="this.OtherSchemaModel"/>

                            <kendo-grid ref="gridOther"
                                :data-source-ref="'otherDataSource'"
                                :columns="this.OtherGridColumns"
                                :editable="true"
                                :filterable="true"
                                :sortable="true"
                                :pageable="true"
                                :resizable="true">
                            </kendo-grid>

                            <other-form ref="otherForm" :saveOtherGrid="saveOtherGrid"/> 
                        </CTab>
                    </CTabs>
                    <br>
                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/project')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
        <file-viewer ref="fileViewer"/>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import projectServices from '../Script/ProjectServices';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import FileViewer from '../../../../shared/FileViewer.vue';
import moment from 'moment';
import { response } from '../../../../infrastructure/constant/response';
import { baseUrl } from '../../../../infrastructure/constant/connection';
import { project } from '../../../../infrastructure/constant/variable';

//Form
import DistributorForm from '../Component/DistributorForm.vue';
import ConsultantForm from '../Component/ConsultantForm.vue';
import AfiliationForm from '../Component/AfiliationForm.vue';
import ForecastForm from './ForecastForm.vue';
import SalesForm from './SalesForm.vue';
import OtherForm from './OtherForm.vue';

//Global Method
import { mixins } from "../../../../shared/Mixins";

export default {
    name: 'ProjectFormPage',
    mixins: [mixins],
    components: {
        'date-picker' : datePicker,
        'distributor-form': DistributorForm,
        'consultant-form': ConsultantForm,
        'afiliation-form': AfiliationForm,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        'forecast-form': ForecastForm,
        'sales-form': SalesForm,
        'other-form': OtherForm,
        'file-viewer': FileViewer,
    },
    async mounted () {
        this.$loading(true);
        if (this.FormType == 'Add') {
            this.ProjectCode = 'Auto';
            this.ProjectName = null;
            var dateNow = moment(new Date()).format("YYYY-MM-DD");
            this.ProjectDate = dateNow;

            this.ProvinceData = this.$globalfunc.getProvinceData();
            this.Province = this.ProvinceData.find(c => c.value == "DKI Jakarta");
            this.CityData = this.$globalfunc.getCityData(this.Province.value);
            this.City = this.CityData.find(c => c.value == "Jakarta Utara");

            this.AreaData = await projectServices.getAreaQuery();
            this.Area = null;
            this.Address = null;
            this.Note = null;
            this.PRM = true;
            this.NonDiscount = false;
            this.Cash = this.CashData[0].value;
            this.Duration = 0;
            this.DiscCash = 0;
            this.PriceTypeData = await projectServices.getPriceTypeQuery();
            this.PriceType = null;
            this.Limit = 0;
            this.KacabData = await projectServices.getKacabQuery();
            this.Kacab = null;
            this.SalesmanData = await projectServices.getSalesmanQuery(this.Kacab);
            this.Salesman = null;
            this.ForecastData = await globalfunc.globalDropdown('ddl_forecast_type');
            this.Forecast = 'None';
            this.TotalPO = 0;
            this.LimitRemains = 0;
            this.KPA = 0;
            this.MinDiscCash = 0;
            this.StorageData = await projectServices.getStorageQuery();
            this.Storage = this.StorageData.find(c => c.value == project.gudang_cikupa);
            this.PreProjectData = await projectServices.getPreProjectQuery();
            this.PreProject = null;
            this.StatusData = [ {value: 'New', label: 'New'} ];
            this.Status = 'New';

            //File
            this.File = '';
            this.FileStatus = 'New';

            //Grid
            this.TypeDetailGridData = await projectServices.getTypeDetailQuery();
            this.AfiliationGridData = [];
            this.DistributorGridData = [];
            this.ConsultantGridData = [];
            this.SalesGridData = [];
            this.OtherGridData = [];
            this.ForecastGridData = [];

            this.SaveType = 'Add';
            document.getElementById('ProjectCode').readOnly = false;
            document.getElementById('Limit').readOnly = false;
            document.getElementById('TotalPO').readOnly = true;
            $('#tab-1').trigger('click');
        }
        else {
            var data = await projectServices.getProjectDetailQuery(this.Id);

            this.ProjectCode = data.project_code;
            this.ProjectName = data.project_name;
            this.ProjectDate = data.project_date;

            this.ProvinceData = this.$globalfunc.getProvinceData();
            this.Province = this.ProvinceData.find(c => c.value == data.project_detail.provinsi);
            this.CityData = this.$globalfunc.getCityData(this.Province.value);
            this.City = this.CityData.find(c => c.value == data.city);

            this.AreaData = await projectServices.getAreaQuery();
            this.Area = this.AreaData.find(c => c.value == data.areacode);
            this.Address = data.project_detail.address;
            this.Note = data.notes;
            this.PRM = data.project_detail.prm;
            this.NonDiscount = false;
            this.Cash = data.project_detail.cash;
            this.Duration = data.project_duration;
            this.DiscCash = data.project_detail.disc_cash;
            this.PriceTypeData = await projectServices.getPriceTypeQuery();
            this.PriceType = this.PriceTypeData.find(c => c.value == data.price_category_id);
            this.Limit = data.project_detail.limit;
            this.KacabData = await projectServices.getKacabQuery();
            this.Kacab = this.KacabData.find(c => c.value == data.project_detail.kacab);
            this.SalesmanData = await projectServices.getSalesmanQuery(this.Kacab);
            this.Salesman = this.SalesmanData.find(c => c.value == data.sales_id);
            this.ForecastData = await globalfunc.globalDropdown('ddl_forecast_type');
            this.Forecast = data.forecast_type;
            
            this.TotalPO = data.project_detail.total_po;
            this.LimitRemains = await projectServices.getLimitRemaining(this.Id, data.forecast_type);
            this.KPA = data.project_detail.kpa;
            this.MinDiscCash = data.project_detail.min_disc_cash;
            this.StorageData = await projectServices.getStorageQuery();
            this.Storage = this.StorageData.find(c => c.value == data.storage_id);
            this.PreProjectData = await projectServices.getPreProjectQuery();
            this.PreProject = this.PreProjectData.find(c => c.value == data.pre_project_id);
            this.StatusData = await globalfunc.globalDropdown('ddl_status_project');
            this.Status = data.status;

            //File
            this.File = data.attachment;
            this.FileStatus = 'Uploaded';

            //grid
            this.TypeDetailGridData = this.$globalfunc.objectToArrayConverter(data.project_discount, 'Project-ProjectTypeDetail');
            this.AfiliationGridData = this.$globalfunc.objectToArrayConverter(data.project_affiliate, 'Project-ProjectAfiliationGrid');
            this.DistributorGridData = this.$globalfunc.objectToArrayConverter(data.project_distributor, 'Project-ProjectDistributorGrid');
            this.ConsultantGridData = this.$globalfunc.objectToArrayConverter(data.project_consultant, 'Project-ProjectConsultantGrid');
            this.SalesGridData = this.$globalfunc.objectToArrayConverter(data.project_sales, 'Project-ProjectSalesGrid');
            this.OtherGridData = this.$globalfunc.objectToArrayConverter(data.project_etc, 'Project-ProjectOther');
            this.ForecastGridData = this.$globalfunc.objectToArrayConverter(data.project_item_forcast, 'Project-ForecastReverse');

            this.SaveType = 'Edit';
            
            document.getElementById('ProjectCode').readOnly = true;
            if(this.Forecast == 'None'){
                document.getElementById('Limit').readOnly = false;
            }else{
                document.getElementById('Limit').readOnly = true;
            }
            if(this.Forecast == 'Forecast 1'){
                document.getElementById('TotalPO').readOnly = false;
            }else{
                document.getElementById('TotalPO').readOnly = true;
            }
            $('#tab-1').trigger('click');

            if(this.View == 'true'){
                $(".save-button").hide();
            }
        }
        this.$loading(false);
    },
    data(){
        return{
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Id: this.$route.params.id,
            Error : 0,
            gridReload: 0,
            gridTypeDetailReload: 0,

            //Detail
            ProjectCode: '',
            ProjectName : '',
            ProjectDate : '',
            Province : '',
            ProvinceData : [],
            City : '',
            CityData : [],
            Area : '',
            AreaData : [],
            Address : '',
            Note : '',
            PRM : false,
            NonDiscount : false,
            CashData : [
                {value: 'Cash', label: 'Cash'},
                {value: 'Credit', label: 'Credit'},
                // {value: 'Deposit', label: 'Deposit'}
            ],
            Cash : '',
            Duration : 0,
            DiscCash : 0,
            PriceType : '',
            PriceTypeData : [],
            Limit : 0,
            Kacab : '',
            KacabData : [],
            Salesman : '',
            SalesmanData : [],
            Forecast : '',
            ForecastData :[],
            TotalPO : 0,
            LimitRemains : 0,
            KPA : 0,
            MinDiscCash : 0,
            Storage : '',
            StorageData : [],
            PreProject : '',
            PreProjectData : [],
            StatusData: [],
            Status: '',

            File: '',
            FileStatus: '',

            //Grid
            TypeDetailGridData: [],
            TypeDetailGridColumns:  [
                { field: "type_detail_id", title: "Kode", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "type_detail_name", title: "Name", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "discount1", title: "Diskon 1 (%)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount2", title: "Diskon 2 (%)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount3", title: "Diskon 3 (%)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount4", title: "Diskon 4 (%)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount5", title: "Diskon 5 (%)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } }
            ],
            TypeDetailGridColumnsNone:  [
                { field: "type_detail_id", title: "Kode", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "type_detail_name", title: "Name", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "discount1", title: "Diskon 1 (%)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount2", title: "Diskon 2 (%)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount3", title: "Diskon 3 (%)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount4", title: "Diskon 4 (%)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount5", title: "Diskon 5 (%)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: 'is_shown', title: "Tampilkan", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" },
                    template: '<input type="checkbox" #= is_shown ? \'checked="checked"\' : "" # class="k-checkbox k-checkbox-md k-rounded-md" />' },
            ],
            TypeDetailSchemaModel: {
                type_detail_id: { type: "string", editable: false },
                type_detail_name: { type: "string", editable: false },
                discount1: { type: "number",editable: true, validation: { min:0 } },
                discount2: { type: "number",editable: true, validation: { min:0 } },
                discount3: { type: "number",editable: true, validation: { min:0 } },
                discount4: { type: "number",editable: true, validation: { min:0 } },
                discount5: { type: "number",editable: true, validation: { min:0 } },
                is_shown: {type: "boolean",editable: true, validation: { min:0 } },
                disc_cash: { type: "number",editable: false},
            },

            DistributorGridData: [],
            DistributorGridColumns:  [
                { field: "distributor_code", title: "Kode", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "distributor_name", title: "Nama", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { command: ['destroy'], title:'&nbsp;', width: 100, attributes: { "class": "k-text-center" } }
            ],
            DistributorSchemaModel: {
                distributor_code: { type: "string", editable: false },
                distributor_name: { type: "string", editable: false },
            },

            ConsultantGridData: [],
            ConsultantGridColumns:  [
                { field: "consultant_name", title: "Nama", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { command: ['destroy'], title:'&nbsp;', width: 100, attributes: { "class": "k-text-center" } }
            ],
            ConsultanSchemaModel: {
                consultant_name: { type: "string", editable: false },
            },

            AfiliationGridData: [],
            AfiliationGridColumns:  [
                { field: "affiliate_name", title: "Nama", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "commision", title: "Komisi", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { command: ['destroy'], title:'&nbsp;', width: 100, attributes: { "class": "k-text-center" } }
            ],
            AfiliationSchemaModel: {
                affiliate_name: { type: "string", editable: false },
                commision: { type: "number", editable: true, validation: { min:0 } },
            },

            SalesGridData: [],
            SalesGridColumns:  [
                { field: "sales_name", title: "Nama", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "position", title: "Posisi", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { command: ['destroy'], title:'&nbsp;', width: 100, attributes: { "class": "k-text-center" } }
            ],
            SalesSchemaModel: {
                sales_name: { type: "string", editable: false },
                position: { type: "string",editable: false },
            },

            OtherGridData: [],
            OtherGridColumns:  [
                { field: "contact_name", title: "Nama", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "position", title: "Posisi", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { command: ['destroy'], title:'&nbsp;', width: 100, attributes: { "class": "k-text-center" } }
            ],
            OtherSchemaModel: {
                contact_name: { type: "string", editable: false },
                position: { type: "string",editable: false },
            },

            ForecastGridData: [],
            Forecast1GridColumns: [
                { command: ['destroy'], title:'&nbsp;', width: 100, attributes: { "class": "k-text-center" } },
                { field: "po", title: "PO", width: 75, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" } },
                { field: "product_code", title: "Kode", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    footerTemplate: "Total Baris<br>#= count #", footerAttributes: { "class": "k-text-center" } },
                { field: "item_name", title: "Nama", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: "base_uom", title: "Satuan Dasar", width: 170, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: "uom_conv", title: "Satuan Konversi", width: 170, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: "conv_amount", title: "Nilai Konversi", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                { field: "quantity", title: "Quantity", width: 125, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "price_project", title: "Harga", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "Total Harga", format:"{0:N0}", footerAttributes: { "class": "k-text-center" } },
                { field: "total_price_project", title: "Total Harga", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "#= kendo.toString(sum, 'N0') #", format:"{0:N0}", footerAttributes: { "class": "k-text-right" } },
                { field: "price_project_discount", title: "Harga Diskon", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }, 
                    footerTemplate: "Total Harga Diskon", format:"{0:N0}", footerAttributes: { "class": "k-text-center" } },
                { field: "total_price_project_discount", title: "Total Harga Diskon", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "#= kendo.toString(sum, 'N0') #", format:"{0:N0}", footerAttributes: { "class": "k-text-right" } },
                { field: "price_project_discount_cash", title: "Harga Diskon Cash", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "Total Harga Diskon Cash", format:"{0:N0}", footerAttributes: { "class": "k-text-center" } },
                { field: "total_price_project_discount_cash", title: "Total Harga Diskon Cash", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "#= kendo.toString(sum, 'N0') #", format:"{0:N0}", footerAttributes: { "class": "k-text-right" } },
            ],
            Forecast1SchemaModel: {
                po: { type: "number", editable: false },
                item_id: { type: "number", editable: false },
                product_code: { type: "string",editable: false },
                item_name: { type: "string",editable: false },
                type_detail_id: { type: "string",editable: false },
                base_uom: { type: "string", editable:false },
                uom_conv: { type: "string", editable:false },
                conv_amount: { type: "number", editable: false },
                quantity: { type: "number", editable: true, validation: { min:1 } },
                //price
                base_price_project: { type: "number", editable: false },
                price_project: { type: "number", editable: false },
                total_price_project: { type: "number", editable: false },
                //price discount
                base_price_project_discount: { type: "number", editable: false },
                price_project_discount: { type: "number", editable: false },
                total_price_project_discount: { type: "number", editable: false },
                //price discount cash
                base_price_project_discount_cash: { type: "number", editable: false },
                price_project_discount_cash: { type: "number", editable: false },
                total_price_project_discount_cash: { type: "number", editable: false },
            },
            Forecast2GridColumns: [
                { command: ['destroy'], title:'&nbsp;', width: 100, attributes: { "class": "k-text-center" } },
                { field: "product_code", title: "Kode", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    footerTemplate: "Total Baris<br>#= count #", footerAttributes: { "class": "k-text-center" } },
                { field: "item_name", title: "Nama", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: "base_uom", title: "Satuan Dasar", width: 170, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: "uom_conv", title: "Satuan Konversi", width: 170, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: "conv_amount", title: "Nilai Konversi", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                { field: "quantity", title: "Quantity", width: 125, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "price_project", title: "Harga", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "Total Harga", format:"{0:N0}", footerAttributes: { "class": "k-text-center" } },
                { field: "total_price_project", title: "Total Harga", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "#= kendo.toString(sum, 'N0') #", format:"{0:N0}", footerAttributes: { "class": "k-text-right" } },
                { field: "price_project_discount", title: "Harga Diskon", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }, 
                    footerTemplate: "Total Harga Diskon", format:"{0:N0}", footerAttributes: { "class": "k-text-center" } },
                { field: "total_price_project_discount", title: "Total Harga Diskon", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "#= kendo.toString(sum, 'N0') #", format:"{0:N0}", footerAttributes: { "class": "k-text-right" } },
                { field: "price_project_discount_cash", title: "Harga Diskon Cash", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "Total Harga Diskon Cash", format:"{0:N0}", footerAttributes: { "class": "k-text-center" } },
                { field: "total_price_project_discount_cash", title: "Total Harga Diskon Cash", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "#= kendo.toString(sum, 'N0') #", format:"{0:N0}", footerAttributes: { "class": "k-text-right" } },
            ],
            Forecast2SchemaModel: {
                item_id: { type: "number", editable: false },
                product_code: { type: "string",editable: false },
                item_name: { type: "string",editable: false },
                type_detail_id: { type: "string",editable: false },
                base_uom: { type: "string", editable:false },
                uom_conv: { type: "string", editable:false },
                conv_amount: { type: "number", editable: false },
                quantity: { type: "number", editable: true, validation: { min:1 } },
                //price
                base_price_project: { type: "number", editable: false },
                price_project: { type: "number", editable: false },
                total_price_project: { type: "number", editable: false },
                //price discount
                base_price_project_discount: { type: "number", editable: false },
                price_project_discount: { type: "number", editable: false },
                total_price_project_discount: { type: "number", editable: false },
                //price discount cash
                base_price_project_discount_cash: { type: "number", editable: false },
                price_project_discount_cash: { type: "number", editable: false },
                total_price_project_discount_cash: { type: "number", editable: false },
            },
        }
    },
    methods: {
        onChangePercentage(){
            this.KPA = this.$globalfunc.percentValidate(this.KPA);
        },
        checkAllTypeDetailClick(e){
            var data = this.$globalfunc.objectToArrayConverter(this.$refs.gridTypeDetail.kendoWidget().dataSource._data, 'Project-ProjectTypeDetail');
            data.map(function(x) { 
                x.is_shown = e; 
                return x
            });
            this.TypeDetailGridData = data;
            
            this.gridTypeDetailReload++;
        },
        onChangeFile(){
            var file = document.getElementById('File').files[0];
            
            this.FileStatus = 'New';
            this.File = file.name;
        },
        fileClick(){
            this.$refs.fileViewer.viewClick(this.File);
        },
        onChange(){
            this.City = '';
            if(this.Province == null){
                this.CityData = [];
            }else{
                this.CityData = this.$globalfunc.getCityData(this.Province.value);
            }
        },
        async onChangeKacab(){
            this.SalesmanData = [];
            this.Salesman = null;
            if(this.Kacab != null){
                this.SalesmanData = await projectServices.getSalesmanQuery(this.Kacab);
            }
        },
        onChangeForecast(){
            if(this.Forecast == 'Forecast 1'){
                this.TotalPO = 1;
                this.Limit = 0;
                document.getElementById('TotalPO').readOnly = false;
                document.getElementById('Limit').readOnly = true;
            }
            else if(this.Forecast == 'Forecast 2'){
                this.TotalPO = 0;
                this.Limit = 0;
                document.getElementById('TotalPO').readOnly = true;
                document.getElementById('Limit').readOnly = true;
            }
            else if (this.Forecast == 'None'){
                this.TotalPO = 0;
                this.Limit = 0;
                document.getElementById('TotalPO').readOnly = true;
                document.getElementById('Limit').readOnly = false;
            }
            //refresh isi grid forecast
            this.resetForecast();
            this.resetTypeDetail();
        },
        onChangeCash(){
            if (this.Cash == 'Credit') {
                this.DiscCash = 0;
                document.getElementById('DiscCash').readOnly = true;
            }
            else {
                document.getElementById('DiscCash').readOnly = false;
            }
        },
        onChangeStorage(){
            //refresh isi grid forecast
            this.resetForecast();
        },
        onChangePriceType(){
            //refresh isi grid forecast
            this.resetForecast();
        },
        addGridClick(type){
            if(type == 'distributor'){
                this.$refs.distributorForm.addClick();
            }
            if(type == 'consultant'){
                this.$refs.consultantForm.addClick();
            }
            if(type == 'afiliation'){
                this.$refs.afiliationForm.addClick();
            }
            if(type == 'Forecast 1'){
                this.$refs.forecastForm.addClick();
            }
            if(type == 'Forecast 2'){
                this.$refs.forecastForm.addClick();
            }
            if(type == 'sales'){
                this.$refs.salesForm.addClick();
            }
            if(type == 'other'){
                this.$refs.otherForm.addClick();
            }
        },
        addDistributorClick(){
            this.addGridClick('distributor');
        },
        saveDistributorGrid(data){
            var gridData = this.$refs.gridDistributor.kendoWidget().dataSource._data;
            var distributorData = this.$globalfunc.objectToArrayConverter(gridData, 'Project-ProjectDistributorGrid');
            distributorData = distributorData.concat(data);
            this.DistributorGridData  = Object.values(distributorData.reduce((a, c) => (a[`${c.distributor_id}${c.distributor_code}${c.distributor_name}`] = c, a), {})); 
        },
        addConsultantClick(){
            this.addGridClick('consultant');
        },
        saveConsultantGrid(data){
            var gridData = this.$refs.gridConsultant.kendoWidget().dataSource._data;
            var consultantData = this.$globalfunc.objectToArrayConverter(gridData, 'Project-ProjectConsultantGrid');
            consultantData = consultantData.concat(data);
            this.ConsultantGridData  = Object.values(consultantData.reduce((a, c) => (a[`${c.consultant_id}${c.consultant_name}`] = c, a), {})); 
        },
        addAfiliationClick(){
            this.addGridClick('afiliation');
        },
        saveAfiliationGrid(data){
            var gridData = this.$refs.gridAfiliation.kendoWidget().dataSource._data;
            var afiliationData = this.$globalfunc.objectToArrayConverter(gridData, 'Project-ProjectAfiliationGrid');
            afiliationData = afiliationData.concat(data);
            this.AfiliationGridData  = Object.values(afiliationData.reduce((a, c) => (a[`${c.affiliate_id}${c.affiliate_name}`] = c, a), {})); 
        },
        addSalesClick(){
            this.addGridClick('sales');
        },
        saveSalesGrid(data){
            var gridData = this.$refs.gridSales.kendoWidget().dataSource._data;
            var salesData = this.$globalfunc.objectToArrayConverter(gridData, 'Project-ProjectSalesGrid');
            salesData = salesData.concat(data);
            this.SalesGridData  = Object.values(salesData.reduce((a, c) => (a[`${c.sales_id}${c.sales_name}${c.position}`] = c, a), {})); 
        },
        addOtherClick(){
            this.addGridClick('other');
        },
        saveOtherGrid(data){
            var gridData = this.$refs.gridOther.kendoWidget().dataSource._data;
            var otherData = this.$globalfunc.objectToArrayConverter(gridData, 'Project-ProjectOther');
            otherData = otherData.concat(data);
            this.OtherGridData  = Object.values(otherData.reduce((a, c) => (a[`${c.contact_id}${c.contact_name}${c.position}`] = c, a), {})); 
        },
        addForecastClick(type){
            if(this.PriceType == '' || this.PriceType == null){
                this.$swal("Error", "Jenis Harga harus dipilih terlebih dahulu", "error");
                return;
            }
            if(this.Storage == '' || this.Storage == null){
                this.$swal("Error", "Gudang harus dipilih terlebih dahulu", "error");
                return;
            }
            if(type == 'Forecast 1'){
                if(String(this.TotalPO) == '' || String(this.TotalPO) == null || this.TotalPO <= 0){
                    this.$swal("Error", "Minimal jumlah PO adalah 1", "error");
                    return;
                }
                else {
                    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
                }
            }
            else {
                window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
            }

            this.addGridClick(type);
        },
        saveForecastGrid(data){
            var gridData = this.$refs.gridForecast.kendoWidget().dataSource._data;
            var currentData = this.$globalfunc.objectToArrayConverter(gridData, 'Project-ForecastInitiate');
            var forecastData = data[0];

            var duplicate = projectServices.duplicateForecastChecker(currentData, forecastData);
            if(duplicate){
                if(this.Forecast == 'Forecast 1'){
                    this.$swal("Error", "Item sudah ada, tidak dapat menginput kode item yang sama pada 1 PO", "error");
                }else if(this.Forecast == 'Forecast 2'){
                    this.$swal("Error", "Item sudah ada, tidak dapat menginput kode item yang sama", "error");
                }
            }else{
                var typeDetailData = this.$refs.gridTypeDetail.kendoWidget().dataSource._data;
                currentData.push(projectServices.calculateForecastData(forecastData, typeDetailData, this.DiscCash));
            }
            this.ForecastGridData = currentData;
        },
        resetForecast(){
            if(this.ForecastGridData.length > 0){
                this.ForecastGridData = [];
                this.gridReload++;
            }
        },
        async resetTypeDetail(){
            this.TypeDetailGridData = await projectServices.getTypeDetailQuery();
        },
        onChangeTypeDetailGrid(e){
            var grid = this.$refs.gridTypeDetail.kendoWidget().dataSource._data;
            var dataItem = e.sender.dataItem($(e.container).parent());
            
            dataItem.discount1 = this.$globalfunc.percentValidate(dataItem.discount1);
            dataItem.discount2 = this.$globalfunc.percentValidate(dataItem.discount2);
            dataItem.discount3 = this.$globalfunc.percentValidate(dataItem.discount3);
            dataItem.discount4 = this.$globalfunc.percentValidate(dataItem.discount4);
            dataItem.discount5 = this.$globalfunc.percentValidate(dataItem.discount5);

            this.TypeDetailGridData = this.$globalfunc.objectToArrayConverter(grid, 'Project-ProjectTypeDetail');

            if(this.Forecast !== 'None'){
                this.recountForecast();
            }
        },
        recountForecast(){
            var gridData = this.$refs.gridForecast.kendoWidget().dataSource._data;
            var typeDetailData = this.$refs.gridTypeDetail.kendoWidget().dataSource._data;
                
            let currentData = [];
            for (let i = 0; i < gridData.length; i++) {
                currentData.push(projectServices.calculateForecastData(gridData[i], typeDetailData, this.DiscCash));
            }
            
            this.ForecastGridData = currentData;
        },
        onChangeDiscCash(){
            this.DiscCash = this.$globalfunc.percentValidate(this.DiscCash);
            if(this.Forecast !== 'None'){
                this.recountForecast();
            }
        },
        inputProjectValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");

            //Validation
            if(this.ProjectCode == '' || this.ProjectCode == null){                
                this.errorShow('errorProjectCode');
            }
            if(this.ProjectName == '' || this.ProjectName == null){
                this.errorShow('errorProjectName');
            }
            if(this.Duration == 0 || this.Duration == null || this.Duration == ''){
                this.errorShow('errorDuration');
            }
            if(String(this.DiscCash) == null || String(this.DiscCash) == ''){
                this.errorShow('errorDiscCash');
            }else if(this.DiscCash > 100){
                this.$swal("Error", "Diskon Cash tidak boleh lebih dari 100", "error" );
                this.Error++;
            }
            if(String(this.TotalPO) == null || String(this.TotalPO) == ''){
                this.errorShow('errorTotalPO');
            }
            if(String(this.LimitRemains) == null || String(this.LimitRemains) == ''){
                this.errorShow('errorLimitRemains');
            }
            if(String(this.KPA) == null || String(this.KPA) == ''){
                this.errorShow('errorKPA');
            }else if(this.KPA > 100){
                this.$swal("Error", "KPA tidak boleh lebih dari 100", "error" )
                this.Error++;
            }
            if(this.ProjectDate == '' || this.ProjectDate == null){
                this.errorShow('errorProjectDate');
            }
            if(this.Province == '' || this.Province == null){
                this.errorShow('errorProvince');
            }
            if(this.City == '' || this.City == null){
                this.errorShow('errorCity');
            }
            if(this.Forecast == 'None'){                
                if(this.Limit == 0 || String(this.Limit) == null || String(this.Limit) == ''){
                    this.errorShow('errorLimit');
                }
            }
            if(this.Salesman == '' || this.Salesman == null){
                this.errorShow('errorSalesman');
            }
            if(this.Area == '' || this.Area == null){
                this.errorShow('errorArea');
            }
            if(this.PriceType == '' || this.PriceType == null){
                this.errorShow('errorPriceType');
            }
            if(this.Kacab == '' || this.Kacab == null){
                this.errorShow('errorKacab');
            }
            if(this.Storage == '' || this.Storage == null){
                this.errorShow('errorStorage');
            }
            if(this.PreProject == '' || this.PreProject == null){
                this.errorShow('errorPreProject');
            }
            if(this.DistributorGridData.length == 0){
                this.$swal("Error", "Distributor tidak boleh kosong", "error" )
                this.Error++;
            }
            if(this.Forecast != 'None'){
                if(this.ForecastGridData.length == 0){
                    this.$swal("Error", "Item pada Forecast tidak boleh kosong", "error" )
                    this.Error++;
                }
            }

            if(this.Duration.toString() == '' || this.Duration == null){
                this.errorShow('errorDuration');
            }
            else {
                if(this.Duration < 0) {
                    this.errorShow('errorDuration', response.fieldRequiredNotMinus);
                }
            }
            
            if(this.Limit.toString() == '' || this.Limit == null){
                this.errorShow('errorLimit');
            }
            else {
                if(this.Limit < 0) {
                    this.errorShow('errorLimit', response.fieldRequiredNotMinus);
                }
            }
        },
        saveClick(){
            this.inputProjectValidation();

            //file
            var file = null
            if(this.File == '' || this.File == null){
                file = null
            } else {
                file = document.getElementById('File').files[0];
            };

            //afiliation
            var afiliationGridData = this.$refs.gridAfiliation.kendoWidget().dataSource._data;
            var afiliationData = this.$globalfunc.objectToArrayConverter(afiliationGridData, 'Project-ProjectAfiliationGrid');

            //distributor
            var distributorGridData = this.$refs.gridDistributor.kendoWidget().dataSource._data;
            var distributorData = this.$globalfunc.objectToArrayConverter(distributorGridData, 'Project-ProjectDistributorGrid');
            
            //typeDetail
            var typeDetailGridData = this.$refs.gridTypeDetail.kendoWidget().dataSource._data;
            var typeDetailData = [];
            for (let i = 0; i < typeDetailGridData.length; i++) {
                var str = { type_detail_id: typeDetailGridData[i].type_detail_id, 
                            type_detail_name: typeDetailGridData[i].type_detail_name,
                            discount1: typeDetailGridData[i].discount1,
                            discount2: typeDetailGridData[i].discount2,
                            discount3: typeDetailGridData[i].discount3,
                            discount4: typeDetailGridData[i].discount4,
                            discount5: typeDetailGridData[i].discount5,
                            is_shown: typeDetailGridData[i].is_shown,
                            discount_cash: parseFloat(this.DiscCash)}
                typeDetailData.push(str);
            };
        
            //consultant
            var consultantGridData = this.$refs.gridConsultant.kendoWidget().dataSource._data;
            var consultantData = this.$globalfunc.objectToArrayConverter(consultantGridData, 'Project-ProjectConsultantGrid');
            
            //sales
            var salesGridData = this.$refs.gridSales.kendoWidget().dataSource._data;
            var salesData = this.$globalfunc.objectToArrayConverter(salesGridData, 'Project-ProjectSalesGrid');
            
            //other
            var otherGridData = this.$refs.gridOther.kendoWidget().dataSource._data;
            var otherData = this.$globalfunc.objectToArrayConverter(otherGridData, 'Project-ProjectOther');
           
            //forecast
            var forecastData = [];
            if(this.Forecast != "None"){
                var forecastGridData = this.$refs.gridForecast.kendoWidget().dataSource._data;
                forecastData = this.$globalfunc.objectToArrayConverter(forecastGridData, 'Project-ProjectForecastSave');
                this.Limit = projectServices.countTotal(forecastGridData);
            };
            
            //Proses
            if(this.Error == 0){
                const detailProjectData = {
                    limit : parseFloat(this.Limit),
                    provinsi : this.Province.value,
                    disc_cash : parseFloat(this.DiscCash),
                    kacab : this.Kacab.value,
                    total_po : parseFloat(this.TotalPO),
                    limit_remains : this.LimitRemains,
                    kpa : this.KPA,
                    min_disc_cash : this.MinDiscCash,
                    address : this.Address,
                    prm : this.PRM,
                    cash : this.Cash
                };

                const projectData = {
                    project_code: this.ProjectCode,
                    project_type: 1, //project = 1, template = 0, retail = 2
                    project_date: this.ProjectDate,
                    project_duration: parseInt(this.Duration),
                    project_name: this.ProjectName,
                    status: this.Status,
                    pre_project_id: this.PreProject.value,
                    forecast_type: this.Forecast,
                    storage_id: this.Storage.value,
                    price_category_id: this.PriceType.value,
                    areacode: this.Area.value,
                    sales_id: this.Salesman.value,
                    city: this.City.value,
                    attachment: file,
                    notes: this.Note,
                    project_detail: detailProjectData,
                    project_affiliate: afiliationData,
                    project_distributor: distributorData,
                    project_consultant: consultantData,
                    project_sales: salesData,
                    project_etc: otherData,
                    project_discount: typeDetailData,
                    project_item_forcast: forecastData
                };

                if (this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data: projectData
                    };

                    projectServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/project' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    });
                }else if (this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id: this.Id,
                        data: projectData
                    };
                    
                    projectServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success")
                            .then(res => {
                                if(res.isConfirmed == true || res.isDismissed == true){
                                    window.location = baseUrl + '/project'
                                }
                            });                  
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    });
                }
            }
        },
        errorShow(errorCode, message){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = message == undefined ? response.fieldRequired : message;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        deleteAttachmentClick(){
            $('#File').val('');
            this.FileStatus = '';
            this.File = '';
        }
    }
}
</script>

<style scoped>
.col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 12%;
    }
    .col-sm-content {
        width: 21%;
    }
    .col-sm-half{
        width: 35%;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>