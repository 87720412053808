<template>
    <div>
        <div class="modal fade" id="forecastDetailModal" tabindex="-1" aria-labelledby="forecastDetailModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="forecastDetailModalLabel" class="font-weight-bold">Select Detail Item : {{ItemCode}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <datasource ref="itemDataSource" :data="this.dataSource" :page-size="10" :schema-model-fields="this.ItemSchemaModel"/>

                        <kendo-grid ref="grid" 
                                    :data-source-ref="'itemDataSource'"
                                    :editable="true"
                                    :sortable="true"
                                    :filterable="true"
                                    :pageable="true"
                                    :resizable="true"
                                    >
                            <kendo-grid-column  :field="'item_name'"
                                                :title="'Nama'"
                                                :width="200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                            <kendo-grid-column  :field="'base_uom'"
                                                :title="'Satuan Dasar'"
                                                :width="150"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                            <kendo-grid-column  :field="'base_price_project'"
                                                :title="'Harga Dasar'"
                                                :width="150"
                                                :format="'{0:N0}'"
                                                :attributes="{style: 'text-align:right;'}"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                            <kendo-grid-column  :field="'uom_conv'"
                                                :title="'Konversi Satuan'"
                                                :width="150"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                            <kendo-grid-column  :field="'price_project'"
                                                :title="'Harga'"
                                                :width="100"
                                                :format="'{0:N0}'"
                                                :attributes="{style: 'text-align:right;'}"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                            <kendo-grid-column  :field="'quantity'"
                                                :title="'Quantity'"
                                                :width="100"
                                                :attributes="{style: 'text-align:right;'}"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                            <kendo-grid-column  :command="{ text: 'Select', click: selectClick }"
                                                :title="'Action'"
                                                :width="100"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                :attributes="{style: 'text-align:center;'}"></kendo-grid-column>
                        </kendo-grid>
                        
                        <br/>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';

export default {
    name: 'ForecastDetailForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
    },
    props: ['saveClick'],
    data: function () {
        return {
            dataSource: [],
            ItemCode: '',
            ItemSchemaModel: {
                item_name: { editable: false},
                base_uom: { editable: false},
                base_price_project: { editable: false},
                uom_conv: { editable: false},
                price_project: { editable: false},
                quantity : {type: "number", editable: true, validation: { min:1 }}
            },
        }
    },
    methods: {
        addClick(data){
            this.ItemCode = data.product_code;
            this.dataSource = this.$globalfunc.objectToArrayConverter(data.ItemForcastDetail, 'Project-ForecastDetail');
            window.$('#forecastDetailModal').modal('show');
        },
        selectClick: function(e) {
            e.preventDefault();
            var grid = this.$refs.grid.kendoWidget();
            var dataItem = grid.dataItem($(e.currentTarget).closest("tr"));
            var errorMessage = "Quantity pada detail item yang dipilih tidak boleh kosong";

            if(dataItem.quantity === undefined){
                this.$swal("Error", errorMessage, "error");
            }else{
                this.$props.saveClick(dataItem);
                window.$('#forecastDetailModal').modal('hide');
            }
        },
    }
}
</script>
